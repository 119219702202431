import StatusTip from './src/StatusTip.vue'

enum StatusTipType {
  DEFAULT,
  NOT_LOGIN, // 未登录
  EXPIRED, // 授权过期
  SYNCHRONIZING, // 数据同步中
  FANS_COUNT_NOT_STANDARD // 粉丝数量未达标
}

const statusTipTypeData = {
  [StatusTipType.NOT_LOGIN]: {
    text: 'common.loginTip',
    btnText: 'common.clickToLogin'
  },
  [StatusTipType.EXPIRED]: {
    text: 'common.expiredTip',
    btnText: 'common.clickToLogin'
  },
  [StatusTipType.SYNCHRONIZING]: {
    text: 'common.synchronizingTip',
    btnText: ''
  },
  [StatusTipType.FANS_COUNT_NOT_STANDARD]: {
    text: 'common.fansNotStandardTip',
    btnText: ''
  }
}

export { StatusTip, StatusTipType, statusTipTypeData }
