import request, { DefaultReturn } from '@/axios'

type Distribution = {
  item: string
  value: number
}

type Contributions = {
  flow: string
  fans_sum: number
  all_sum: number
}

export interface FansData {
  fans_data: {
    all_fans_num: number
    active_days_distributions: Distribution[]
    age_distributions: Distribution[]
    device_distributions: Distribution[]
    flow_contributions: Contributions[]
    gender_distributions: Distribution[]
    geographical_distributions: Distribution[]
    interest_distributions: Distribution[]
  }
}

export function fetchFansData(params: { authAccountId: string }) {
  return request.get<DefaultReturn<FansData>>({
    url: `/dy/realTime/getDyUserFansData`,
    params
  })
}

export interface FansSource {
  list: {
    percent: string
    source: string
  }[]
}

// 粉丝来源
export function fetchFansSource(params: { authAccountId: string }) {
  return request.get<DefaultReturn<FansSource>>({
    url: `/dy/realTime/getDyUserFansSource`,
    params
  })
}
