import axios, { AxiosError } from 'axios'

import { AxiosInstance, InternalAxiosRequestConfig, RequestConfig, AxiosResponse } from './types'
import { ElMessage, ElNotification } from 'element-plus'
import { REQUEST_TIMEOUT } from '@/constants'
import { encrypt, decrypt } from '@/utils/jsencrpy'
import {
  generateAesKey,
  encryptBase64,
  encryptWithAes,
  decryptBase64,
  decryptWithAes
} from '@/utils/crypto'
import { HttpStatus } from '@/constants/resEnum'
import errorCode from '@/constants/errorCode'
import { useUserStore } from '@/store/modules/user'
import { usePageLoading } from '@/hooks/web/usePageLoading'

const { loadStart, loadDone } = usePageLoading()

const encryptHeader = 'encrypt-key'

export const PATH_URL = import.meta.env.VITE_API_BASE_PATH

const abortControllerMap: Map<string, AbortController> = new Map()

const axiosInstance: AxiosInstance = axios.create({
  timeout: REQUEST_TIMEOUT,
  baseURL: PATH_URL
})

axiosInstance.interceptors.request.use((res: InternalAxiosRequestConfig) => {
  const controller = new AbortController()
  const url = res.url || ''
  res.signal = controller.signal

  const isEncrypt = res.headers?.isEncrypt === 'true'

  if (import.meta.env.VITE_APP_ENCRYPT === 'true') {
    // 当开启参数加密
    if (isEncrypt && (res.method === 'post' || res.method === 'put')) {
      // 生成一个 AES 密钥
      const aesKey = generateAesKey()
      res.headers[encryptHeader] = encrypt(encryptBase64(aesKey))
      res.data =
        typeof res.data === 'object'
          ? encryptWithAes(JSON.stringify(res.data), aesKey)
          : encryptWithAes(res.data, aesKey)
    }
  }

  abortControllerMap.set(url, controller)
  return res
})

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    if (import.meta.env.VITE_APP_ENCRYPT === 'true') {
      // 加密后的 AES 秘钥
      const keyStr = res.headers[encryptHeader]

      // 加密
      if (keyStr != null && keyStr != '') {
        const data = res.data
        // 请求体 AES 解密
        const base64Str = decrypt(keyStr)
        // base64 解码 得到请求头的 AES 秘钥
        const aesKey = decryptBase64(base64Str.toString())
        // aesKey 解码 data
        const decryptData = decryptWithAes(data, aesKey)
        // 将结果 (得到的是 JSON 字符串) 转为 JSON
        res.data = JSON.parse(decryptData)
      }
    }
    const url = res.config.url || ''
    abortControllerMap.delete(url)
    // 这里不能做任何处理，否则后面的 interceptors 拿不到完整的上下文了

    // 未设置状态码则默认成功状态
    const code = res.data.code || HttpStatus.SUCCESS
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']

    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
      return res.data
    }
    if (code === 401) {
      // const result = await refreshToken()
      const userStore = useUserStore()
      userStore.logout()
      ElMessage({ message: '无效的会话，或者会话已过期，请重新登录。', type: 'error' })
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === HttpStatus.SERVER_ERROR) {
      if (!res.config.url?.includes('login')) {
        ElMessage({ message: msg, type: 'error' })
      }
      return Promise.reject(new Error(msg))
    } else if (code === HttpStatus.WARN) {
      ElMessage({ message: msg, type: 'warning' })
      return Promise.reject(new Error(msg))
    } else if (code !== HttpStatus.SUCCESS) {
      ElNotification.error({ title: msg })
      return Promise.reject('error')
    } else {
      return Promise.resolve(res.data)
    }
    return res
  },
  (error: AxiosError) => {
    console.log('err： ' + error) // for debug
    ElMessage.error(error.message)
    return Promise.reject(error)
  }
)

const service = {
  request: (config: RequestConfig) => {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptors) {
        config = config.interceptors.requestInterceptors(config as any)
      }
      config.showLoading && loadStart()
      axiosInstance
        .request(config)
        .then((res) => {
          resolve(res)
        })
        .catch((err: any) => {
          reject(err)
        })
        .finally(() => {
          config.showLoading && loadDone()
        })
    })
  },

  cancelRequest: (url: string | string[]) => {
    const urlList = Array.isArray(url) ? url : [url]
    for (const _url of urlList) {
      abortControllerMap.get(_url)?.abort()
      abortControllerMap.delete(_url)
    }
  },

  cancelAllRequest() {
    for (const [_, controller] of abortControllerMap) {
      controller.abort()
    }
    abortControllerMap.clear()
  }
}

export default service
