import router from './router'
// import { useAppStoreWithOut } from '@/store/modules/app'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { usePageLoading } from '@/hooks/web/usePageLoading'
// import { NO_REDIRECT_WHITE_LIST } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { StatusTipType } from './components/StatusTip'
import { useStorage } from './hooks/web/useStorage'

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

const { getStorage, removeStorage } = useStorage()

router.beforeEach(async (to, from, next) => {
  start()
  loadStart()
  const permissionStore = usePermissionStoreWithOut()
  const userStore = useUserStoreWithOut()

  // true为动态路由添加完成
  if (permissionStore.getIsAddRouters) {
    // 根据登录的用户状态控制main view区域的展示
    userStore.checkStatusTipType(to.meta.needCheckStatus as StatusTipType[])
    // 隐藏抖音返回的code参数
    if (to.query.code) {
      delete to.query.code
      delete to.query.state
      delete to.query.scopes
      // 等待登录或者绑定的添加
      await userStore.decodeCode()
      next({ ...to })
      return
    }
    next()
    return
  }

  // const appStore = useAppStoreWithOut()
  await permissionStore.generateRoutes('static')
  permissionStore.getAddRouters.forEach((route) => {
    router.addRoute(route as unknown as RouteRecordRaw) // 动态添加可访问路由表
  })

  let redirectPath = to.path
  const tempRedirect = getStorage('redirect_path')
  let query = to.query

  if (tempRedirect) {
    redirectPath = tempRedirect
    removeStorage('redirect_path')
  }

  const redirect = decodeURIComponent(redirectPath as string)

  tempRedirect && (query = { ...query, ...getQuery(redirect) })
  // const redirectPath = from.query.redirect || to.path
  const nextData =
    to.path === redirect ? { ...to, replace: true, query } : { path: redirect, query }
  if (userStore.getUserId) {
    await userStore
      .getDyAccountInfo(userStore.getUserId)
      .then(() => {
        userStore.getUserBindingList().catch(() => {})
      })
      .catch(() => {})
  }
  permissionStore.setIsAddRouters(true)
  next(nextData)
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})

function getQuery(url: string) {
  let u = url.split('?')
  if (typeof u[1] === 'string') {
    u = u[1].split('&')
    const get: Record<string, any> = {}
    for (const i in u) {
      const j = u[i].split('=')
      get[j[0]] = decodeURIComponent(j[1])
    }
    return get
  } else {
    return {}
  }
}
