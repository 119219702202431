<script lang="tsx">
import { Icon } from '@/components/Icon'
import { propTypes } from '@/utils/propTypes'
import { ElDialog } from 'element-plus'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'MyDialog',

  props: {
    modelValue: propTypes.bool.def(false),
    title: propTypes.string.def('Dialog'),
    fullscreen: propTypes.bool.def(false),
    jsxSlots: propTypes.object.def(() => {})
  },
  emits: ['update:modelValue'],

  setup(props, { emit, slots }) {
    const close = () => {
      emit('update:modelValue', false)
    }

    const mergeSlost = computed(() => {
      return { ...slots, ...props.jsxSlots }
    })

    return () => (
      <>
        <ElDialog
          modelValue={props.modelValue}
          fullscreen={false}
          destroy-on-close
          lock-scroll
          top="0"
          close-on-click-modal={false}
          show-close={false}
        >
          {{
            header: () => (
              <div class="flex justify-between items-center h-54px pl-15px pr-15px relative">
                {mergeSlost.value?.title ? mergeSlost.value.title() : ''}
                <div
                  onClick={close}
                  class="h-54px flex justify-between items-center absolute top-[50%] right-15px translate-y-[-50%]"
                >
                  <Icon
                    class="cursor-pointer is-hover !h-54px"
                    icon="vi-ep:close"
                    size={24}
                    hover-color="var(--el-color-primary)"
                    color="var(--el-color-info)"
                  />
                </div>
              </div>
            ),
            default: () => (mergeSlost.value?.default ? mergeSlost.value.default() : ''),
            footer: () => (mergeSlost.value?.footer ? mergeSlost.value.footer() : '')
          }}
        </ElDialog>
      </>
    )
  }
})
</script>

<style lang="less">
.@{elNamespace}-overlay-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.@{elNamespace}-dialog {
  margin: 0 !important;
  border-radius: 4px;
  width: 480px !important;
  &__header {
    height: 54px;
    padding: 0 !important;
    margin-right: 0 !important;
    border-bottom: 1px solid var(--el-border-color);
    font-weight: bold;
  }

  &__body {
    padding: 15px !important;
  }

  &__footer {
    padding: 16px !important;
  }

  &__headerbtn {
    top: 0;
  }
}
</style>
