import { defineStore } from 'pinia'
import { store } from '../index'
import { UserLoginType, UserType } from '@/api/login/types'
import { ElMessage } from 'element-plus'
import { login } from '@/api/user'
import { getURLParams } from '@/utils'
import { StatusTipType } from '@/components/StatusTip'
import { addNewAccount, authBinding, fetchAccountInfo, fetchUserBindingList } from '@/api/user'
import { HttpStatus } from '@/constants/resEnum'
import { useStorage } from '@/hooks/web/useStorage'
import { UserAuthStatus } from '@/constants/userAuthStatus'
import { fetchFansData } from '@/api/fansProtrayalAnalysis'

interface UserState {
  userInfo?: UserType
  tokenKey: string
  token: string
  roleRouters?: string[] | AppCustomRouteRecordRaw[]
  rememberMe: boolean
  loginInfo?: UserLoginType
  statusTipType: StatusTipType
  bindingUserList: UserType[]
  currentAccountOpenId: string
}

export const TokenKey = 'Authorization'

const { getStorage, setStorage, clear } = useStorage()
const SCOPE =
  'user_info,video.list.bind,video.data.bind,fans.check,fans.data.bind,discovery.ent,star_top_score_display,item.comment,data.external.item,data.external.fans_favourite,data.external.user,data.external.fans_source,live.room.base,live.room.audience,live.room.interactive'
const tenantId = '000000'
const urlParam = getURLParams()
const socicalLoginTypeKey = 'LOGIN_TYPE'
const tokenInfo = getStorage(TokenKey)

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      userInfo: undefined,
      tokenKey: TokenKey,
      token: tokenInfo?.access_token,
      roleRouters: undefined,
      // 记住我
      rememberMe: true,
      loginInfo: undefined,
      statusTipType: StatusTipType.DEFAULT,
      bindingUserList: [],
      currentAccountOpenId: ''
    }
  },

  actions: {
    // 检测当前登录账号的状态信息，控制layout appview的显示
    checkStatusTipType(param: StatusTipType[]) {
      console.log(this.getUserList)
      if (!param) {
        return this.setStatusTipType(StatusTipType.DEFAULT)
      }
      param.find((item) => {
        let flag = true
        switch (item) {
          case StatusTipType.NOT_LOGIN:
            flag = this.isLogin
            break
          case StatusTipType.EXPIRED:
            flag = this.userInfo?.authStatus === UserAuthStatus.AUTHORIZED
            break
          case StatusTipType.SYNCHRONIZING:
            flag = this.userInfo?.authTime
              ? !(
                  new Date(this.userInfo!.authTime).getTime() + 2 * 24 * 3600 * 1000 >
                  new Date().getTime()
                ) // true为正在同步中
              : false
            break
          case StatusTipType.FANS_COUNT_NOT_STANDARD:
            flag = !!this.userInfo?.allFansNum
            break
        }
        if (flag) {
          this.setStatusTipType(StatusTipType.DEFAULT)
        } else {
          this.setStatusTipType(item)
          return item
        }
      })
    },

    // 退出登录
    logout() {
      this.setToken('')
      this.setUserInfo(undefined)
      this.setStatusTipType(StatusTipType.NOT_LOGIN)
      clear()
    },

    // 获取抖音账号信息
    async getDyAccountInfo(id: string, showLoading = false) {
      const userInfo = await fetchAccountInfo(id, showLoading)
      const result = await fetchFansData({
        authAccountId: id
      })

      userInfo.data.allFansNum = result.data.fans_data?.all_fans_num ?? 0

      this.setUserInfo(userInfo.data)
    },

    // 获取绑定列表
    async getUserBindingList() {
      const userList = await fetchUserBindingList()
      this.setUserList(userList)
      return userList
    },

    // 解析第三方code并且登录
    decodeCode() {
      const type = getStorage(socicalLoginTypeKey)
      // 无type时, 不是通过getSocialCode进入，直接复制带code链接进入，登录时会缺少接口需要的source
      if (!type) return

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        if (urlParam && urlParam.code && urlParam.state && urlParam.scopes) {
          const params = {
            socialCode: urlParam.code as string,
            socialState: urlParam.state as string,
            tenantId: tenantId,
            source: type as string,
            grantType: 'social',
            clientId: import.meta.env.VITE_APP_CLIENT_ID
          }

          try {
            if (this.isLogin) {
              // 绑定新账号
              await addNewAccount(params)
            } else {
              // 登录
              const result = await login(params)
              setStorage(this.tokenKey, {
                ...result.data
              })

              this.setToken(result.data.access_token)
            }

            const userList = await this.getUserBindingList()

            const loginTarget =
              userList.find((item) => {
                return item.openId === getStorage(this.tokenKey).openid
              }) ?? userList[0]

            await this.getDyAccountInfo(loginTarget.id)
          } finally {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },

    // 获取第三方登录code
    getSocialCode(source: 'douyin' | 'wechat' = 'douyin') {
      const { protocol, host, pathname, search } = window.location
      const REDIRECT_URI = protocol + '//' + host + '/'
      return authBinding(source, tenantId).then((res: any) => {
        if (res.code === HttpStatus.SUCCESS) {
          setStorage(socicalLoginTypeKey, source)
          // 保存重定向路径
          setStorage('redirect_path', pathname + search)
          // 获取授权地址跳转
          window.location.href = (res.data as string).replace(
            /redirect_uri=.+&/,
            `redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=${SCOPE}&`
          )
        } else {
          ElMessage.error(res.msg)
        }
      })
    },

    setUserList(userList: UserType[]) {
      this.bindingUserList = userList
    },

    setStatusTipType(statusTipType) {
      this.statusTipType = statusTipType
    },

    setCurrentOpenid(opneId: string) {
      this.currentAccountOpenId = opneId
    },

    setTokenKey(tokenKey: string) {
      this.tokenKey = tokenKey
    },

    setToken(token: string) {
      this.token = token
    },

    setUserInfo(userInfo?: UserType) {
      this.userInfo = userInfo
    },

    setRoleRouters(roleRouters: string[] | AppCustomRouteRecordRaw[]) {
      this.roleRouters = roleRouters
    },

    setRememberMe(rememberMe: boolean) {
      this.rememberMe = rememberMe
    },

    setLoginInfo(loginInfo: UserLoginType | undefined) {
      this.loginInfo = loginInfo
    }
  },

  getters: {
    isLogin(): boolean {
      return !!this.userInfo
    },

    hasToken(): boolean {
      return !!this.token
    },
    getUserList(): UserType[] {
      return this.bindingUserList
    },

    getUserId(): string | undefined {
      return this.userInfo?.id
    },
    getTokenKey(): string {
      return this.tokenKey
    },
    getToken(): string {
      return this.token
    },
    getUserInfo(): UserType | undefined {
      return this.userInfo
    },
    getRoleRouters(): string[] | AppCustomRouteRecordRaw[] | undefined {
      return this.roleRouters
    },
    getRememberMe(): boolean {
      return this.rememberMe
    },
    getLoginInfo(): UserLoginType | undefined {
      return this.loginInfo
    }
  },

  persist: true
})

export const useUserStoreWithOut = () => {
  return useUserStore(store)
}
