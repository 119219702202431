import request, { DefaultReturn } from '@/axios'
import { UserType } from '../login/types'

// 账号解除绑定
export function authUnlock(socialId: string) {
  return request.delete<DefaultReturn>({
    url: '/auth/unlock/' + socialId
  })
}

// 新增账号绑定
export function addNewAccount(params: SignInParams) {
  return request.post<DefaultReturn<UserType>>({
    url: `/auth/social/callback`,
    data: params
  })
}

// 获取抖音账号信息
export function fetchAccountInfo(id: string, showLoading = false) {
  return request.get<DefaultReturn<UserType>>({
    url: `/dy/accountAuth/${id}`,
    showLoading
  })
}

// 获取当前账号绑定的三方账号列表
export function fetchUserBindingList() {
  return request.get<UserType[]>({
    url: '/dy/accountAuth/getAccountAuthList'
  })
}

export interface SignInParams {
  socialCode: string
  socialState: string
  tenantId: string
  source: string
  clientId: string
  grantType: string
}

export const login = (data: SignInParams) => {
  return request.post({
    url: `/auth/login`,
    data,
    headers: {
      isEncrypt: true
    }
  })
}

// 获取第三方跳转链接
export function authBinding(source: string, tenantId: string) {
  return request.get({
    url: '/auth/binding/' + source,
    params: {
      tenantId: tenantId,
      domain: window.location.host
    }
  })
}
