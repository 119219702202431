import service from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

export interface DefaultReturn<T = any> {
  code: number
  data: T
  msg: string
}

const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType, showLoading } = option

  const userStore = useUserStoreWithOut()
  const token = userStore.getToken
  return service.request({
    url: url,
    method,
    params,
    data: data,
    responseType: responseType,
    showLoading: showLoading ?? false,
    headers: {
      'Content-Type': CONTENT_TYPE,
      clientid: import.meta.env.VITE_APP_CLIENT_ID,
      [userStore.getTokenKey]: token ? 'Bearer ' + token : '',
      ...headers
    }
  })
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<T>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<T>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<T>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<T>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  }
}
