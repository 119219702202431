import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'
import { StatusTipType } from '@/components/StatusTip'

const { t } = useI18n()
// const defaultRedirect = '/video-data-analysis/creation'
const defaultRedirect = '/hot-ranking'
export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: defaultRedirect,
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  // 消息通知
  {
    path: '/notify',
    component: Layout,
    name: 'Notify',
    redirect: '/notify/center',
    meta: {
      hidden: true
    },
    children: [
      {
        path: 'center',
        name: 'NotifyCenter',
        component: () => import('@/views/NotifyCenter/NotifyCenter.vue'),
        meta: {}
      }
    ]
  },

  // 账号数据分析
  {
    path: '/account-data-analysis',
    component: Layout,
    redirect: '/account-data-analysis/data-analysis-overview',
    name: 'accountDataAnalysis',
    meta: {
      title: t('router.accountDataAnalysis'),
      icon: '&#xe60a;',
      alwaysShow: true
    },
    children: [
      {
        path: 'data-analysis-overview',
        component: () => import('@/views/accountDataAnalysis/dataAnalysisOverview.vue'),
        name: 'dataAnalysisOverview',
        meta: {
          title: t('router.dataAnalysisOverview'),
          noCache: true,
          affix: true,
          needCheckStatus: [
            StatusTipType.NOT_LOGIN,
            StatusTipType.EXPIRED,
            StatusTipType.SYNCHRONIZING
          ]
        }
      }
    ]
  },

  // 视频数据分析
  {
    path: '/video-data-analysis',
    component: Layout,
    redirect: '/video-data-analysis/creation-data-overview',
    name: 'videoDataAnalysis',
    meta: {
      title: t('router.videoDataAnalysis'),
      icon: '&#xe63c;',
      alwaysShow: true
    },
    children: [
      {
        path: 'creation-data-overview',
        component: () => import('@/views/videoDataAnalysis/creationDataOverview.vue'),
        name: 'creationDataOverview',
        meta: {
          title: t('router.creationDataOverview'),
          noCache: true,
          affix: true,
          needCheckStatus: [
            StatusTipType.NOT_LOGIN,
            StatusTipType.EXPIRED,
            StatusTipType.SYNCHRONIZING
          ]
        }
      },
      {
        path: 'creation',
        component: () => getParentLayout(),
        name: 'creationList',
        redirect: '/video-data-analysis/creation/video-list',
        meta: {
          title: t('router.creationList')
        },

        children: [
          {
            path: 'video-list',
            component: () => import('@/views/videoDataAnalysis/creationList.vue'),
            name: 'videoList',
            meta: {
              title: t('router.creationList'),
              hidden: true,
              canTo: true,
              breadcrumb: false,
              needCheckStatus: [
                StatusTipType.NOT_LOGIN,
                StatusTipType.EXPIRED,
                StatusTipType.SYNCHRONIZING
              ]
            }
          },
          {
            path: 'creation-comment',
            component: () => import('@/views/videoDataAnalysis/creationComment.vue'),
            name: 'creationComment',
            meta: {
              title: t('router.creationComment'),
              hidden: true,
              canTo: true,
              needCheckStatus: [
                StatusTipType.NOT_LOGIN,
                StatusTipType.EXPIRED,
                StatusTipType.SYNCHRONIZING
              ]
            }
          },
          {
            path: 'creation-data',
            component: () => import('@/views/videoDataAnalysis/creationData.vue'),
            name: 'creationData',
            meta: {
              title: t('router.creationData'),
              hidden: true,
              canTo: true,
              needCheckStatus: [
                StatusTipType.NOT_LOGIN,
                StatusTipType.EXPIRED,
                StatusTipType.SYNCHRONIZING
              ]
            }
          }
        ]
      }
    ]
  },

  // 抖音推荐推荐分析
  // {
  //   path: '/dy-recommend-analysis',
  //   component: Layout,
  //   redirect: '/dy-recommend-analysis/traffic-analysis',
  //   name: 'dyRecommendAnalysis',
  //   meta: {
  //     title: t('router.dyRecommendAnalysis'),
  //     icon: '&#xe695;',
  //     alwaysShow: true
  //   },
  //   children: [
  //     {
  //       path: 'traffic-analysis',
  //       component: () => import('@/views/dyRecommendAnalysis/trafficAnalysis.vue'),
  //       name: 'trafficAnalysis',
  //       meta: {
  //         title: t('router.trafficAnalysis'),
  //         noCache: true,
  //         affix: true,
  //         needCheckStatus: [
  //           StatusTipType.NOT_LOGIN,
  //           StatusTipType.EXPIRED,
  //           StatusTipType.SYNCHRONIZING
  //         ]
  //       }
  //     }
  //   ]
  // },

  // 粉丝画像分析
  {
    path: '/fans-portayal-analysis',
    component: Layout,
    redirect: '/fans-portayal-analysis/fans-portayal',
    name: 'fansPortrayalAnalysis',
    meta: {
      title: t('router.fansPortrayalAnalysis'),
      icon: '&#xe626;',
      alwaysShow: true
    },
    children: [
      {
        path: 'fans-portayal',
        component: () => import('@/views/fansPortrayalAnalysis/fansPortayal.vue'),
        name: 'fansPortayal',
        meta: {
          title: t('router.fansPortayal'),
          noCache: true,
          affix: true,
          needCheckStatus: [
            StatusTipType.NOT_LOGIN,
            StatusTipType.EXPIRED,
            StatusTipType.SYNCHRONIZING,
            StatusTipType.FANS_COUNT_NOT_STANDARD
          ]
        }
      }
    ]
  },

  // 热榜排行
  {
    path: '/hot-ranking',
    component: Layout,
    redirect: '/hot-ranking/real-words',
    name: 'hotRanking',
    meta: {
      title: t('router.hotRanking'),
      icon: '&#xe611;'
    },
    children: [
      {
        path: 'real-words',
        component: () => import('@/views/hotRanking/realHotWordsRanking.vue'),
        name: 'realHotWordsRanking',
        meta: {
          title: t('router.realHotWordsRanking'),
          noCache: true,
          affix: true
          // needCheckStatus: [
          //   StatusTipType.NOT_LOGIN,
          //   StatusTipType.EXPIRED,
          //   StatusTipType.SYNCHRONIZING
          // ]
        }
      },
      {
        path: 'risng-words',
        component: () => import('@/views/hotRanking/risingWordsRanking.vue'),
        name: 'risingWordsRanking',
        meta: {
          title: t('router.risingWordsRanking'),
          noCache: true,
          affix: true
          // needCheckStatus: [
          //   StatusTipType.NOT_LOGIN,
          //   StatusTipType.EXPIRED,
          //   StatusTipType.SYNCHRONIZING
          // ]
        }
      },
      {
        path: 'videos',
        component: () => import('@/views/hotRanking/hotVideosRanking.vue'),
        name: 'hotVideosRanking',
        meta: {
          title: t('router.hotVideosRanking'),
          noCache: true,
          affix: true
          // needCheckStatus: [
          //   StatusTipType.NOT_LOGIN,
          //   StatusTipType.EXPIRED,
          //   StatusTipType.SYNCHRONIZING
          // ]
        }
      },
      {
        path: 'subjects',
        component: () => import('@/views/hotRanking/subjectRanking.vue'),
        name: 'subjectRanking',
        meta: {
          title: t('router.subjectRanking'),
          noCache: true,
          affix: true
          // needCheckStatus: [
          //   StatusTipType.NOT_LOGIN,
          //   StatusTipType.EXPIRED,
          //   StatusTipType.SYNCHRONIZING
          // ]
        }
      },
      {
        path: 'live-streaming',
        component: () => import('@/views/hotRanking/liveStreamingRanking.vue'),
        name: 'liveStreamingRanking',
        meta: {
          title: t('router.liveStreamingRanking'),
          noCache: true,
          affix: true
          // needCheckStatus: [
          //   StatusTipType.NOT_LOGIN,
          //   StatusTipType.EXPIRED,
          //   StatusTipType.SYNCHRONIZING
          // ]
        }
      }
      // {
      //   path: 'dy-star-hot',
      //   component: () => import('@/views/hotRanking/dyStarMapTalentHotRanking.vue'),
      //   name: 'dyStarMapTalentHotRanking',
      //   meta: {
      //     title: t('router.dyStarMapTalentHotRanking'),
      //     noCache: true,
      //     affix: true,
      //     needCheckStatus: [
      //       StatusTipType.NOT_LOGIN,
      //       StatusTipType.EXPIRED,
      //       StatusTipType.SYNCHRONIZING
      //     ]
      //   }
      // },
      // {
      //   path: 'dy-star-index',
      //   component: () => import('@/views/hotRanking/dyStarMapTalentIndexRanking.vue'),
      //   name: 'dyStarMapTalentIndexRanking',
      //   meta: {
      //     title: t('router.dyStarMapTalentIndexRanking'),
      //     noCache: true,
      //     affix: true,
      //     needCheckStatus: [
      //       StatusTipType.NOT_LOGIN,
      //       StatusTipType.EXPIRED,
      //       StatusTipType.SYNCHRONIZING
      //     ]
      //   }
      // }
    ]
  },

  // 个人信息
  {
    path: '/personal',
    component: Layout,
    redirect: '/personal/personal-info',
    name: 'personal',
    meta: {},
    children: [
      {
        path: 'personal-info',
        component: () => import('@/views/personalInfo/personalInfo.vue'),
        name: 'personalInfo',
        meta: {
          title: t('router.personalInfo'),
          noCache: true,
          icon: '&#xe698;',
          affix: true,
          needCheckStatus: [StatusTipType.NOT_LOGIN, StatusTipType.EXPIRED]
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
